<template>
  <div class="news">
    <div class="container">
      <h3 class="bigTittle">
        新闻资讯
      </h3>
      <ul class="newsList">
        <li
          v-for="(item,index) in newsList2"
          :key="index"
          @click="onEditOrder(item)"
        >
          <div class="contentCol" :class="item.img?'':'contentCol2'">
            <h4 class="tittle">{{item.tittle}}</h4>
            <div class="desc">{{item.desc}}</div>
            <div class="time">{{item.time}}</div>
          </div>
          <div class="imgCol" v-if="item.img">
            <img :src="item.img">
          </div>
        </li>
      </ul>
      <!-- <div class="seeMore">
        <span>查看全部</span>
        <a-icon type="right" />
      </div> -->
    </div>
    <div class="container">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

      <!--新增入库单抽屉-->
    <transition name="slide">
      <input-view
        v-if="isInputDrawerShow"
        :rowData="currentRepairOrderRow"
        class="slide-dialog"
        @dialog-close="onInputDrawerClose"
      />
    </transition>

  </div>
</template>

<script>
import InputView from './NewsDetail/InputView'
export default {
  name: 'newsList',
 components: {
    InputView
  },
  data () {
    return {
        // 录入/补录 抽屉显示
      isInputDrawerShow: false,
      // 外修单ID
      currentRepairOrderRow: {},
      newsList: [
        {
          img: require('../assets/images/news2024-12-13_01.png'),
          tittle: '胡开忠教授受邀出席WIPO国际条约制定大会',
          desc: '2024年11月29日至12月6日，世界知识产权组织知识产权与遗传资源、传统知识和民间文学艺术政府间委员会（IGC）第48届和第49届会议在瑞士日内瓦总部召开。',
          time: '2024-12-13',
          from: '来源：中南财经政法大学知识产权研究中心',
          flag: 16,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '教育部国家版权局教育系统要用正版软件',
          desc: '近日，教育部、国家版权局发出通知，为贯彻落实《国务院办公厅关于印发政府机关使用正版软件管理办法的通知》（国办发〔2013〕88号），进一步完善教育系统软件正版化工作长效机制，推进教育系统软件正版化工作规范化、常态化、制度化。',
          time: '2024-12-13',
          from: '来源：央广网',
          flag: 15,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '尼泊尔最新版权提案提议设立知识产权警察',
          desc: '近期，尼泊尔在2024年《版权法》草案提议设立知识产权警察，这在该国尚属首次。',
          time: '2024-12-13',
          from: '来源：中国保护知识产权网',
          flag: 14,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '省知识产权研究会2024年会暨学术研讨会召开',
          desc: '为深入学习贯彻习近平法治思想，深刻把握党的二十届三中全会关于“构建高水平社会主义市场经济体制，建立高效的知识产权综合管理体制”的重要指示，彰显新时代深化知识产权领域改革的湖北力量，充分发挥知识产权在强国建设中的积极作用，为进一步推进知识产权治理能力现代化提供更加有力的支撑，湖北省法学会知识产权法学研究会和湖北省知识产权研究会于12月7日在中南财经政法大学知识产权研究中心召开2024年年会暨学术研讨会。',
          time: '2024-12-13',
          from: '来源：中南财经政法大学知识产权研究中心',
          flag: 13,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '“区块链+版权”赋能文化产业发展',
          desc: '“大唐链上线运营至今，已服务数十家企业。目前，链上存储可信数据所产生的直接经济收益过亿元，用户超过23万人。基于大唐链开发运营的‘嗨艺购’‘DTC++（简称大头菜）’‘文化数字丝绸之路’等业务平台，可以为版权登记、交易、授权、溯源、保护领域提供全生命周期服务，真正让技术应用落地，对发挥信息化驱动引领作用具有重要意义。”2月6日，大唐西市集团信息部部长陈亮在接受采访时说。',
          time: '2024-12-12',
          from: '来源：宣传教育与统战处',
          flag: 12,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '第四届著作权集体管理论坛共议创新与热点',
          desc: '第四届著作权集体管理论坛日前在京举行。来自政府、司法机关、国际组织、高等院校、行业协会、音乐公司等的嘉宾通过对集体管理管理制度的发展与完善、生成式人工智能给著作权集体管理带来的机遇与挑战、著作权集体管理相关法律制度的完善和实践等议题的讨论，展示了我国著作权集体管理事业30余年来取得的成就，并就著作权集体如何在面对新时代、新科技的挑战中进一步发挥桥梁作用，为产业各方服务给出了思考。',
          time: '2024-12-12',
          from: '来源：北青网',
          flag: 11,
        },
        {
          // img: '../assets/images/news2024-12-11.png',
          tittle: '浅谈数字时代摄影作品的版权问题（陈劲风）',
          desc: '在当今这个高度数字化且瞬息万变的时代，摄影作品的创作与传播正以前所未有的速度和规模发展。摄影不再是专业摄影师的专属，智能手机的普及让每个人都能成为摄影师，社交媒体的兴起则为摄影作品的分享和传播提供了广阔的平台。',
          time: '2024-12-11',
          from: '来源：学习强国',
          flag: 10,
        },
        {
          img: require('../assets/images/news2024-12-11.png'),
          tittle: '全国政协委员孙宝林建议：确认版权公共服务机构调解版权纠纷法律效力',
          desc: '近年来，版权意识已深入人心。同时，伴随着计算机软件、音视频和自媒体等新领域、新业态的快速发展，以及人民群众法律意识的增强，我国版权纠纷案件数量呈现增长趋势。如何从版权纠纷的源头开展预防、从前端进行化解？',
          time: '2024-12-11',
          from: '来源：光明网',
          flag: 9,
        },
        {
          // img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '数字时代如何保护新闻作品版权',
          desc: '优质的内容是媒体的最大优势，新闻作品版权是媒体的核心资产。数字时代不仅极大丰富了文化作品的载体样式，也加速了信息传播方式的变革。随着传统媒体与新兴媒体的深度融合，新闻出版业在迅猛发展的同时，相关的版权保护问题也变得更加错综复杂。',
          time: '2024-12-11',
          from: '来源：中国版权协会',
          flag: 8,
        },
        {
          // img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '孙宝林 讲好版权文化故事',
          desc: '习近平总书记在党的二十大报告中强调，全面建设社会主义现代化国家，必须坚持中国特色社会主义文化发展道路。发展面向现代化、面向世界、面向未来的，民族的科学的大众的社会主义文化，激发全民族文化创新创造活力，增强实现中华民族伟大复兴的精神力量。',
          time: '2024-12-11',
          from: '来源：中国版权保护中心',
          flag: 7,
        },
        {
          // img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '一生创作百部作品 琼瑶留下超10亿版权费遗产',
          desc: '横跨文学和影视，她是史上最成功的女性“作家商人”。作家琼瑶于2024年12月4日下午1时许被发现在家中离世，终年86岁。',
          time: '2024-12-05',
          from: '来源：中国企业家杂志',
          flag: 6,
        },
        {
          // img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '浙江高院“版权AI智审”应用入选',
          desc: '近日，国家知识产权强国建设工作部际联席会议办公室发布知识产权强国建设第三批典型案例。浙江省高级人民法院牵头研发的“版权AI智审”数字化应用成功入选。',
          time: '2024-10-29',
          from: '来源：人民法院报',
          flag: 5,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news10.png',
          tittle: '中文出版物知识产权评价中心成立',
          desc: '为深入贯彻习近平总书记思想，推动知识产权评价体系建设，落实国家知识产权局《知识产权强国建设纲要》和《关于加强知识产权鉴定工作的指导意见》，近日，在武汉大学和湖北省版权局的支持下“中文出版物知识产权评价中心”成立。',
          time: '2023-02-23',
          flag: 4,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news07.png',
          tittle: '可信大数据有限公司参加武汉市首场数字经济大会',
          desc: '3月31日下午，2022年武汉科技成果转化首场对接活动数字经济专场在武钢体育馆举行。本次会议成果提供方包括华中科技大学、武汉大学、武汉科技大学等一批在汉高校及科研院所，武汉钢铁有限公司、中冶集团武汉勘察研究院有限公司、可信大数据有限公司、黑芝麻智能科技有限公司等一批在汉企业为成果承接方。24个科技成果转化项目，签约总合作金额达3.1亿元以上。',
          time: '2022-04-01',
          flag: 3,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news04.png',
          tittle: '湖北省版权保护中心与可信大数据签约共推中国知链项目',
          desc: '3月29日下午，湖北省版权保护中心与可信大数据有限公司举行合作协议签字仪式，共同推进中国知链——国家区块链创新应用试点项目落地湖北并发展壮大。中心主任郑凌辉、可信公司董事长兼总经理胡江南出席签字仪式。',
          time: '2022-03-30',
          from: '来源：湖北省版权保护中心 作者：刘迟 文 / 图 ',
          flag: 2,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '国家级区块链创新应用试点',
          desc: '根据《关于组织申报区块链创新应用试点的通知》（中网办秘字〔2021〕1482号），经过中央网信办、中央宣传部、国务院办公厅[电子政务办公室]、最高人民法院、最高人民检察院、教育部、工业和信息化部、民政部、司法部、人力资源社会保障部、国家卫生健康委、中国人民银行、国家税务总局、中国银保监会、中国证监会、国家能源局、国家外汇管理局等17个中央委办部门和单位严格评审，中国知链获批“国家区块链创新应用试点”。',
          time: '2021-12-22',
          flag: 1,
        },
      
        // {
        //   img: 'https://baikewh.oss-cn-hangzhou.aliyuncs.com/static/ipchain/newImg.png',
        //   tittle: '知链平台简介2',
        //   desc: '段落示意222：蚂蚁金服设计平台 design.alipay.com，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。蚂蚁金服设计平台 design.alipay.com，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。',
        //   time: '2017-10-01 18:00'
        // },
        
      ],
      newsList2: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total:0
      },
    }
  },

  mounted () {
    // this.init()
    this.pagination.total = this.newsList.length*1
    this.initPageLists()
  },
  methods: {
    init () {
      this.$store.commit('setActiveManue', 6)

    },
    // 录入/补录 抽屉打开
    onEditOrder(data) {
      console.log("onEditOrder...")
      this.currentRepairOrderRow = data
      this.isInputDrawerShow = true
    },
     // 录入/补录 抽屉关闭
    onInputDrawerClose() {
      this.isInputDrawerShow = false
    },

    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.newsList2 = this.newsList.slice((pageNum-1)*10,pageNum*10)
    },
    initPageLists(){
        this.newsList2 = this.newsList.slice(0,10)
    }
  }
}
</script>

<style lang='scss' scoped>
.news {
  min-height: 650px;
  padding-bottom: 50px;
  .container:first-child{
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
    margin: 16px auto;
    padding: 28px 0 0;
    color: rgba(0, 18, 22, 0.85);
    .bigTittle {
      padding: 4px 4%;
      font-size: 24px;
    }
  }
}
.newsList {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 4%;
    cursor: pointer;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    .imgCol {
      width: 10%;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .contentCol {
      width: 70%;
      .tittle {
        font-size: 20px;
      }
      .desc {
        color: rgba(0, 18, 22, 0.65);
        margin: 16px 0 14px;
      }
      .time{
          color: rgba(0, 18, 22, 0.65); 
      }
    }
    .contentCol2{
      width: 100%;
    }
  }
  &>li:last-child{
      border-bottom: none;
  }
}
.seeMore {
  background: #fafafa;
  padding: 17px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 18, 22, 0.65);
  cursor: pointer;
  .anticon {
    margin-left: 4px;
    font-size: 12px;
  }
}
.el-pagination{
    text-align: right;
    margin-top:16px;
}
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .newsList {
    li {
        flex-wrap: wrap;
      .imgCol {
        order: 1;
        width: 100%;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 16px;
      }
      .contentCol {
        order: 2;
        width: 100%;
      }
    }
  }
}
</style>
